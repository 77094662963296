import React, { Component } from 'react'
import "./HomeBody.css";
import ReactRevealText from "react-reveal-text";
import email from '../resources/email.png'
import github from '../resources/github.png'
import linkedin from '../resources/linkedin.png'
import Fade from 'react-reveal/Fade';

export class HomeBody extends Component {
    constructor (){
        super();
        this.state = {show: false};
        this.state = {showLate: false};
    }

    // If the component was mounted successfully
    componentDidMount() {
        setTimeout(() =>{
            this.setState({show: true});
        }, 1000 );
        setTimeout(() =>{
            this.setState({showLate: true});
        }, 2000 );
        setTimeout(() =>{
            this.setState({showEmail: true});
        }, 4000 );
        setTimeout(() =>{
            this.setState({showGit: true});
        }, 4500 );
        setTimeout(() =>{
            this.setState({showLinkedIn: true});
        }, 5000 );
        
    }

    render() {
        return (
            <div className="body-wrapper">
                <div className="bodyContent">
                    <ReactRevealText show={this.state.show}>
                        Hello.
                    </ReactRevealText>
                </div>
                <div className="bodyContent">
                    <ReactRevealText show={this.state.show}>
                        I am Mark Buenaflor.
                    </ReactRevealText>
                </div>
                <div className="bodyContent">
                    <ReactRevealText show={this.state.showLate}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&#8226;Software Developer
                    </ReactRevealText>
                    <ReactRevealText show={this.state.showLate}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&#8226;Game Developer
                    </ReactRevealText>
                </div>
                <div className="bodyInformation">
                    {this.state.showEmail && 
                        <div className="emailContent">
                            <Fade>
                                <img src={email}/>
                                <p>marktbuenaflor@gmail.com</p>
                            </Fade>
                        </div>
                    }
                    {this.state.showGit && 
                        <div className="gitContent">
                            <Fade>
                                <img src={github}/>
                                <p><a href="https://github.com/Nomiiya">github.com/Nomiiya</a></p>
                            </Fade>
                        </div>
                    }
                    {this.state.showLinkedIn && 
                        <div className="linkedInContent">
                            <Fade>
                                <img src={linkedin}/>
                                <p><a href="https://www.linkedin.com/in/markterencebuenaflor">linkedin.com/in/markterencebuenaflor</a></p>
                            </Fade>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default HomeBody