import React, { Component } from 'react'
import "./Header.css"
import pfp from '../resources/pfp.png'

export class Header extends Component {
    render() {
        return (
            <div className="navWrapper">
                <ul class="nav">
                    <li>
                        <img src={pfp}/>
                    </li>
                </ul>
            </div>
            
        )
    }
}

export default Header
