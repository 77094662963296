import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Header from './component/layout/Header';
import HomeBody from './component/pages/HomeBody';
import About from './component/pages/About';

class Home extends React.Component{
    render(){
        return (
            <Router>
            <div>
                <div className="container">
                    <Header />
                    <Route exact path="/" render = {props => (
                        <React.Fragment>
                            <HomeBody />
                        </React.Fragment>
                    )} />
                    <Route exact path="/about" render ={props => (
                        <React.Fragment>
                            <About />
                        </React.Fragment>
                    )} />
                </div>
            </div>
            </Router>
        );
    }
}

ReactDOM.render(
        <Home />, 
        document.getElementById('root')
);
serviceWorker.unregister();