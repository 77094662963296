import React, { Component } from 'react'
import "./About.css"

export class about extends Component {
    render() {
        return (
            <div className = "aboutStyle">
                <p> About Page </p>
            </div>
        )
    }
}

export default about
